import styles from "./HUD.module.css";
import SectionMenu from "./section-menu/SectionMenu";
import SlicedText from "../../common-components/sliced-text/SlicedText";
import {useTopic} from "../../hooks/zustand/useTopic";
import {useEffect, useRef} from "react";
import useFrameAnimation from "../../hooks/useFrameAnimation";
import MenuModal from "./menu-modal/MenuModal";
import TopBar from "./top-bar/TopBar";
import HomePageText from "./home-page-text/HomePageText";

export default function HUD() {

    const ref = useRef();

    const currentTopic = useTopic(state => state.topic)

    const currentTransform = useRef(0);

    const startProgress = useRef(0)
    const goalProgress = useRef(0);

    const animationStartTime = useRef(0)

    const isFirstRender = useRef(true)

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (currentTopic !== null) {
            startProgress.current = 0;
            goalProgress.current = 20;
        } else {
            startProgress.current = 20;
            goalProgress.current = 0;
        }

        animationStartTime.current = Date.now();

    }, [currentTopic])

    useFrameAnimation(() => {
        const animationDuration = 3000;

        let animationProgress = (Date.now() - animationStartTime.current) / animationDuration;
        animationProgress = Math.max(Math.min(animationProgress, 1), 0)
        animationProgress = easeInOutCubic(animationProgress)

        const progress = interpolate(startProgress.current, goalProgress.current, animationProgress);

        currentTransform.current = progress;
        ref.current.style.transform = `translateX(calc(-50% + ${-progress}vw)) translateY(-50%)`
    })

    return (
        <div className={styles.hud} ref={ref}>
            <TopBar/>
            <MenuModal/>
            {/*<HomePageText/>*/}
            <div className={styles.footer}>Air System Company - Szymon Gołąb | {new Date().getFullYear()} Warsaw, Poland</div>
        </div>
    )
}

function interpolate(startValue, endValue, factor) {
    // Make sure the factor is within [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the interpolated value
    return startValue * (1 - factor) + endValue * factor;
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}


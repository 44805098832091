import { useEffect, useRef } from 'react'

export default function useFrameAnimation(callback, deps) {

  const animationId = useRef(0)

  useEffect(() => {
    const animate = () => {
      callback()
      animationId.current = requestAnimationFrame(animate)
    }

    animationId.current = requestAnimationFrame(animate)

    return () => cancelAnimationFrame(animationId.current)
  }, deps)
}

import styles from './Overlay.module.css'
import HUD from "./hud/HUD";
import SidePanel from "./side-panel/SidePanel";
import MenuModal from "./hud/menu-modal/MenuModal";

export default function Overlay() {
    return (
        <div className={styles.overlay}>
            <HUD/>
            <SidePanel topic={'cargo'} visible={true}/>
        </div>
    )
}

import styles from './SidePanel.module.css'
import {useTopic} from "../../hooks/zustand/useTopic";
import {useEffect, useRef} from "react";
import useFrameAnimation from "../../hooks/useFrameAnimation";
import ReturnBtn from "./return-btn/ReturnBtn";
import useIsMobile from "../../hooks/useIsMobile";
import SlicedText from "../../common-components/sliced-text/SlicedText";
import content from "../../content/content.json"
import useLanguageStore from "../../hooks/zustand/useLanguageStore";

export default function SidePanel() {

    const language = useLanguageStore(state => state.language)

    const isMobile = useIsMobile();

    const ref = useRef();

    const [currentTopic, topicNonNull] = useTopic(state => [state.topic, state.topicNonNull])

    const isVisible = currentTopic !== null;

    const currentTransform = useRef(0);

    const startProgress = useRef(0)
    const goalProgress = useRef(0);

    const animationStartTime = useRef(0)

    const isFirstRender = useRef(true)

    useEffect(() => {

        const transform = isMobile ? 100 : 40

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (currentTopic !== null) {
            goalProgress.current = transform;
        } else {
            goalProgress.current = 0;
        }

        if (currentTopic !== null) {
            startProgress.current = 0;
            goalProgress.current = transform;
        } else {
            startProgress.current = transform;
            goalProgress.current = 0;
        }

        animationStartTime.current = Date.now();

    }, [currentTopic])

    useFrameAnimation(() => {

        const animationDuration = 2000;

        let animationProgress = (Date.now() - animationStartTime.current) / animationDuration;
        animationProgress = Math.max(Math.min(animationProgress, 1), 0)
        animationProgress = easeInOutCubic(animationProgress)

        const progress = interpolate(startProgress.current, goalProgress.current, animationProgress);

        currentTransform.current = progress;
        ref.current.style.transform = `translateX(calc(100% - ${progress}vw)`
    })

    return (
        <div
            ref={ref}
            className={styles.modalContainer}
            style={{
                width: (isMobile ? 100 : 40) + 'vw'
            }}
        >
            <div className={styles.contentContainer} style={{transform: `translateY(${isVisible ? 0 : 50}px)`}}>

                <div className={styles.titleContainer}>

                    <div className={styles.title}>
                        <div
                            dangerouslySetInnerHTML={{__html: content[language][topicNonNull]?.title.toUpperCase()}}></div>
                    </div>

                    <ReturnBtn/>

                </div>

                {topicNonNull === "connections" &&
                    <div dangerouslySetInnerHTML={{__html: content[language]["connections"]?.text}}/>
                }

                {topicNonNull === "products" &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: content[language].products.text1}}></p>

                        <div className={styles.imageGrid}>
                            <div className={styles.imageContainer}>
                                <img src={"./images/engine.png"} alt={''}/>
                            </div>
                            <div className={styles.imageContainer}>
                                <img src={"./images/materials.png"} alt={''}/>
                            </div>
                            <div className={styles.imageContainer}>
                                <img src={"./images/parts.png"} alt={''}/>
                            </div>
                        </div>

                        <p>{content[language].products.text2}</p>

                        {/*<div className={styles.imageContainer}>*/}
                        {/*    <img src={"./images/gf40tu-fi.jpg"} alt={''}/>*/}
                        {/*</div>*/}
                        {/*<div className={styles.imageContainer}>*/}
                        {/*    <img src={"./images/gt80tu-fi.jpg"} alt={''}/>*/}
                        {/*</div>*/}
                    </>
                }

                {topicNonNull === "contact" &&
                    <>

                        <div style={{color: 'rgb(200, 200, 200)'}}>Szymon Gołąb Air System</div>
                        <br/>
                        <div style={{color: 'rgb(200, 200, 200)'}}>Kochanowskiego 10A/44</div>
                        <div style={{color: 'rgb(200, 200, 200)'}}>01-864 Warszawa</div>
                        <br/>
                        <div style={{color: 'rgb(200, 200, 200)'}}>NIP: 1182285893</div>
                        <div style={{color: 'rgb(200, 200, 200)'}}>REGON: 528872725</div>

                        <br/>

                        <div>{content[language].contact.contactUs}</div>

                        <br/>

                        <div style={{backgroundColor: 'rgb(40, 40, 40)', padding: '10px'}}>
                            <div style={{color: 'gray'}}>CEO</div>
                            <div style={{color: 'rgb(242, 97, 63)'}}>Szymon Gołąb</div>
                            <div style={{color: 'rgb(200, 200, 200)'}}>+48 668 619 984</div>
                            <div style={{color: 'rgb(200, 200, 200)'}}>szymon.golab@airssystem.com</div>
                        </div>

                        <br/>

                        <div style={{backgroundColor: 'rgb(40, 40, 40)', padding: '10px'}}>
                            <div style={{color: 'gray'}}>Sales Specialist</div>
                            <div style={{color: 'rgb(242, 97, 63)'}}>Bogdan Lutsak</div>
                            <div style={{color: 'rgb(200, 200, 200)'}}>+48 539 826 645</div>
                            <div style={{color: 'rgb(200, 200, 200)'}}>bogdan.lutsak@airssystem.com</div>
                        </div>


                        <br/>
                        <br/>

                        <div style={{color: 'gray', alignSelf: "center"}}>Website designed and created by Svido</div>
                        <div style={{color: 'gray', alignSelf: "center", cursor: "pointer"}}
                             onClick={() => window.open("https://svido.app/", '_blank').focus()}>www.svido.app
                        </div>

                    </>
                }

                <div className={styles.elbowDecoration}>
                    <div/>
                    <div/>
                </div>
            </div>
        </div>
    )
}

function interpolate(startValue, endValue, factor) {
    // Make sure the factor is within [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the interpolated value
    return startValue * (1 - factor) + endValue * factor;
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

import {create} from "zustand";

export const useTopic = create((set) => ({
    topic: null,
    topicNonNull: null,
    setTopic: (newValue) => set((state) => ({
        topic: newValue,
        topicNonNull: newValue !== null ? newValue : state.topicNonNull,
    })),
}))

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/models/truck.glb --transform
*/

import React, { useRef } from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import * as THREE from "three";

useTexture.preload('./models/truck.webp')

export default function Model(props) {

    const truckTexture = useTexture('./models/truck.webp')

    return (
        <sprite {...props}>
            <spriteMaterial map={truckTexture} depthTest={false}/>
        </sprite>
    )
}

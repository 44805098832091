import {Canvas} from "@react-three/fiber"
import Plane from "./Plane";
import {CameraControls, OrbitControls, OrthographicCamera, Stats, useTexture} from "@react-three/drei";
import {Bloom, EffectComposer, Noise, Pixelation} from "@react-three/postprocessing"
import {BlurPass, Resizer, KernelSize, Resolution} from 'postprocessing'
import {UnrealBloomPass} from "three-stdlib";
import {OutputPass} from "three/examples/jsm/postprocessing/OutputPass";
import {extend, useFrame} from "@react-three/fiber";

import Bodzio from "./Bodzio"
import BodzioLine from "./BodzioLine"
import Truck from "./Truck"
import People from "./People"
import Container from "./Container"
import Camera from "./Camera"

import * as THREE from 'three'
import {ACESFilmicToneMapping} from "three";
import ArrowField from "./ArrowField";
import PlusField from "./PlusField";

extend({UnrealBloomPass, OutputPass});

export default function Scene() {

    return (
        <Canvas shadows={true}
                dpr={window.devicePixelRatio}
                gl={{
                    powerPreference: "high-performance",
                    precision: "highp",
                    antialias: true,
                    logarithmicDepthBuffer: true,
                    sortObjects: false
                }}
                camera={{
                    near: 50,
                    fov: 0.6,
                    position: [-60, 60, 100],
                }}
                flat={true}
        >
            <Camera/>

            <Bodzio/>
            <BodzioLine/>

            <color attach={'background'} args={['#0C0C0C']}/>

            <ArrowField
                sectionIndex={0}
                position={[1 + 12 / 16, 0, -(1 + 8 / 16) + 0.016]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            />

            <Truck
                scale={.4}
                position={[2 + 1 / 16, 0, -1 / 4 + 0.016 - 2 / 16]}
            />

            <PlusField
                position={[1 + 12 / 16, 0, 1 / 4 + 0.016]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
                sectionIndex={1}
                topic={'connections'}
            />

            <ArrowField
                sectionIndex={1}
                position={[1 + 4 / 16, 0, 2 / 4 + 0.016]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
            />

            <PlusField
                position={[-3 / 4, 0, 2 / 4 + 0.016]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
                sectionIndex={2}
                topic={'products'}
            />

            <ArrowField
                sectionIndex={2}
                position={[-1 - 1 / 4, 0, 1 + 0.016]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            />

            <PlusField
                position={[-1 - 0 / 4, 0, 3 + 0.016]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
                sectionIndex={3}
                topic={'contact'}
            />

            <Container
                scale={0.42}
                position={[-3.3 / 4, 0, 0.5 / 4 + 0.016 - 1.5 / 16]}
            />

            <People scale={0.1} rotation={[0, -0.5, 0]} position={[-1 - 1.5 / 8, 0, 3 + 0.016 - 2/8]}/>

        </Canvas>
    )
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/models/container.glb --transform
*/

import React, {useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'

useTexture.preload('./models/containers.webp')

export default function Model(props) {
    const containerTexture = useTexture('./models/containers.webp')

    return (

        <sprite {...props}>
            <spriteMaterial map={containerTexture} depthTest={false}/>
        </sprite>
    )
}

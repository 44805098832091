import styles from './ReturnBtn.module.css'
import {useMemo, useState} from "react";
import {useTopic} from "../../../hooks/zustand/useTopic";

export default function ReturnBtn({visible}) {

    const [isHovered, setIsHovered] = useState();
    const setTopic = useTopic(state => state.setTopic)

    const hoverAudio = useMemo(() => new Audio('./sounds/hover.wav'), []);
    const clickAudio = useMemo(() => new Audio('./sounds/click.wav'), []);

    const handleHover = () => {
        setIsHovered(true)
        hoverAudio.play()
    }

    const handleClick = () => {
        setTopic(null)
        setIsHovered(false)
        clickAudio.play()
    }

    return (
        <div className={styles.container}
             onPointerEnter={handleHover}
             onPointerLeave={() => setIsHovered(false)}
             onClick={handleClick}
             style={{
                 transform: `translateY(${visible ? 10 : 0}px)`,
             }}
        >
            <div className={styles.tablet1}
                 style={{
                     transform: `rotate(-45deg) scaleX(${isHovered ? -1 : 1})`,
                     backgroundColor: isHovered ? 'white' : 'rgb(242, 97, 63)'
                 }}
            >

            </div>

            <div className={styles.tablet2}
                style={{
                transform: `rotate(45deg) scaleX(${isHovered ? -1 : 1})`,
                    backgroundColor: isHovered ? 'white' : 'rgb(242, 97, 63)'
            }}
                 >
            </div>
        </div>
    )
}

import {create} from "zustand";

export const useSectionOpened = create((set, get) => ({
    sectionOpenedArray: [true, false, false, false],
    setSectionOpened: (index) => set((state) => {
        if (index >= 0 && index < state.sectionOpenedArray.length) {
            const newArray = [...state.sectionOpenedArray];
            newArray[index] = true;
            return { sectionOpenedArray: newArray };
        }
        return state;
    }),
    getIsSectionOpened: (index) => {
        const state = get();
        if (index >= 0 && index < state.sectionOpenedArray.length) {
            return state.sectionOpenedArray[index];
        }
        return undefined;
    },
}))

import styles from "./TopBar.module.css";
import SlicedText from "../../../common-components/sliced-text/SlicedText";
import SectionMenu from "../section-menu/SectionMenu";
import {useTopic} from "../../../hooks/zustand/useTopic";
import {useCurrentSection} from "../../../hooks/zustand/useCurrentSection";
import {useSectionOpened} from "../../../hooks/zustand/useSectionOpened";
import useIsMobile from "../../../hooks/useIsMobile";
import {useIsMenuModalOpen} from "../../../hooks/zustand/useIsMenuModalOpen";

import content from "../../../content/content.json"
import useLanguageStore from "../../../hooks/zustand/useLanguageStore";

export default function TopBar() {

    const [topic, setTopic] = useTopic(state => [state.topic, state.setTopic])
    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])
    const setSectionOpened = useSectionOpened(state => state.setSectionOpened)

    const setIsMenuModalOpen = useIsMenuModalOpen(state => state.setIsMenuModalOpen)

    const isSidePanelOpen = topic !== null

    const isMobile = useIsMobile()

    const language = useLanguageStore(state => state.language)

    const navItemStyle = {
        opacity: isSidePanelOpen ? 0 : 1,
        color: isSidePanelOpen ? 'rgb(242, 97, 63)' : "white"
    }

    const handleClickMenu = () => {
        setIsMenuModalOpen(true)
    }

    const handleClickHome = () => {
        setCurrentSection(0)
    }

    const handleClickConnections = () => {
        setCurrentSection(1)
        setSectionOpened(1)

        const delay = currentSection === 1 ? 0 : 2000

        setTimeout(() => {
            setTopic('connections')
        }, delay)
    }

    const handleClickProducts = () => {
        setCurrentSection(2)
        setSectionOpened(2)

        const delay = currentSection === 2 ? 0 : 2000

        setTimeout(() => {
            setTopic('products')
        }, delay)
    }

    const handleClickContact = () => {
        setCurrentSection(3)
        setSectionOpened(3)

        const delay = currentSection === 3 ? 0 : 2000

        setTimeout(() => {
            setTopic('contact')
        }, delay)
    }

    return (
        <div className={styles.topBar}>

            <div className={styles.gridContainer}>

                {isMobile &&
                    <div/>
                }

                {!isMobile &&
                    <>
                        <div className={styles.navItem}
                             style={navItemStyle}
                             onClick={handleClickHome}
                        >
                            {content[language].menu.home}
                        </div>
                        <div className={styles.navItem}
                             style={navItemStyle}
                             onClick={handleClickConnections}
                        >
                            {content[language].menu.aboutUs}
                        </div>
                    </>
                }


                <div className={styles.titleContainer}
                >
                    <div className={styles.title}>
                        <SlicedText>AIR SYSTEM</SlicedText>
                    </div>
                    <div className={styles.subtitle}>INTERNATIONAL TECH TRADING</div>
                    <SectionMenu/>
                </div>

                {!isMobile &&
                    <>
                        <div className={styles.navItem}
                             style={navItemStyle}
                             onClick={handleClickProducts}
                        >
                            {content[language].menu.techProducts}
                        </div>

                        <div className={styles.navItem} style={navItemStyle} onClick={handleClickContact}>
                            <span style={{color: 'rgb(242, 97, 63)'}}>{"<<"}</span>
                            &nbsp;{content[language].menu.contact}&nbsp;
                            <span style={{color: 'rgb(242, 97, 63)'}}>{">>"}</span>
                        </div>
                    </>
                }

                {isMobile &&
                    <div className={styles.menuIcon}
                         onClick={handleClickMenu}
                    >
                        <div/>
                        <div/>
                        <div/>
                    </div>
                }



            </div>
        </div>
    )
}

import styles from './MenuModal.module.css'
import {useIsMenuModalOpen} from "../../../hooks/zustand/useIsMenuModalOpen";
import {useCurrentSection} from "../../../hooks/zustand/useCurrentSection";
import {useMemo} from "react";
import {useTopic} from "../../../hooks/zustand/useTopic";
import {useSectionOpened} from "../../../hooks/zustand/useSectionOpened";

// import content from "../../../content/content.json"
// import useLanguageStore from "../../../hooks/zustand/useLanguageStore";
// const language = useLanguageStore(state => state.language)
// {content[language].menu.aboutUs}

import content from "../../../content/content.json"
import useLanguageStore from "../../../hooks/zustand/useLanguageStore";


export default function MenuModal() {

    const [language, setLanguage] = useLanguageStore(state => [state.language, state.setLanguage])

    const [isMenuModalOpen, setIsMenuModalOpen] = useIsMenuModalOpen(state => [state.isMenuModalOpen, state.setIsMenuModalOpen])

    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])

    const setTopic = useTopic(state => state.setTopic)
    const setSectionOpened = useSectionOpened(state => state.setSectionOpened)

    const sections = [
        "home",
        "aboutUs",
        "techProducts",
        "contact"
    ]

    const handleHover = () => {
        hoverAudio.play()
    }

    const handleClick = (index) => {

        const topics = [
            null,
            "connections",
            "products",
            "contact"
        ]

        const delay = currentSection === index ? 0 : 2000

        setTimeout(() => {
            setTopic(topics[index])
            setSectionOpened(index)
        }, delay)

        setCurrentSection(index)
        setIsMenuModalOpen(false)
        clickAudio.play()
    }

    const hoverAudio = useMemo(() => new Audio('./sounds/hover.wav'), []);
    const clickAudio = useMemo(() => new Audio('./sounds/click.wav'), []);

    return (
        <div className={styles.modalContainer} style={{
            opacity: isMenuModalOpen ? 1 : 0,
            backgroundColor: isMenuModalOpen ? 'rgba(0, 0, 0, 0.75)' : 'rgba(0, 0, 0, 0.5)',
            pointerEvents: isMenuModalOpen ? 'all' : 'none'
        }}>
            <div className={styles.sectionsContainer}>
                {sections.map((sectionName, index) => {
                    return (
                        <div className={styles.sectionName}
                             style={{
                                 opacity: isMenuModalOpen ? 1 : 0,
                                 transform: `translateY(${isMenuModalOpen ? 0 : -20}px)`
                             }}
                             onClick={() => handleClick(index)}
                             onPointerEnter={handleHover}
                        >
                            {content[language].menu[sectionName].toUpperCase()}
                        </div>
                    )
                })}
                <br/>
                <div className={styles.exitBtn}
                     style={{
                         opacity: isMenuModalOpen ? 1 : 0,
                         transform: `translateY(${isMenuModalOpen ? 0 : -20}px)`
                     }}
                     onClick={() => setIsMenuModalOpen(false)}>
                    <div className={styles.tablet1}/>
                    <div className={styles.tablet2}/>
                </div>
            </div>

            <div className={styles.languageChangeContainer}>
                <div className={styles.imageContainer} onClick={() => setLanguage('en')}>
                    <img src={"./flags/en.svg"} alt={'en'}/>
                </div>
                <div className={styles.imageContainer} onClick={() => setLanguage('pl')}>
                    <img src={"./flags/pl.svg"} alt={'pl'}/>
                </div>

                <div className={styles.imageContainer} onClick={() => setLanguage('uk')}>
                    <img src={"./flags/uk.svg"} alt={'uk'}/>
                </div>
            </div>
        </div>
    )
}

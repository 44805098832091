import styles from "./SectionMenu.module.css";
import {useCurrentSection} from "../../../hooks/zustand/useCurrentSection";
import {useState} from "react";
import MenuModal from "../menu-modal/MenuModal";
import {useIsMenuModalOpen} from "../../../hooks/zustand/useIsMenuModalOpen";
import content from "../../../content/content.json"
import useLanguageStore from "../../../hooks/zustand/useLanguageStore";

export default function SectionMenu() {

    const currentSection = useCurrentSection(state => state.currentSection)

    const [isMenuModalOpen, setIsMenuModalOpen] = useIsMenuModalOpen(state => [state.isMenuModalOpen, state.setIsMenuModalOpen])

    const language = useLanguageStore(state => state.language)

    return (
        <>
            <div className={styles.sectionMenu}
                style={{
                    height: currentSection ? '30px' : '10px',
                    width: currentSection ? '200px' : '30px',
                    transform: `translateY(${isMenuModalOpen ? 20 : 0}svh)`,
                    opacity: isMenuModalOpen ? 0 : 1
                }}
                 onClick={() => setIsMenuModalOpen(true)}
            >
                <div className={styles.textContainer}
                    style={{
                        transform: `translateY(${-( (4 - (currentSection + 1))/ 4) * 100}%)`
                    }}
                >
                    <div className={styles.item}></div>
                    <div className={styles.item}>{content[language].menu.aboutUs}</div>
                    <div className={styles.item}>{content[language].menu.techProducts}</div>
                    <div className={styles.item}>{content[language].menu.contact}</div>
                </div>
            </div>
        </>
    )
}

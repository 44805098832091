import React, {useEffect, useMemo, useRef, useState} from "react";
import {useCursor, useTexture} from "@react-three/drei";
import {useCurrentSection} from "../hooks/zustand/useCurrentSection";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {useTopic} from "../hooks/zustand/useTopic";
import {useSectionOpened} from "../hooks/zustand/useSectionOpened";

export default function PlusField(props) {

    const texture = useTexture('./images/plus.png', (texture) => {
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;
    })


    const [isHovered, setIsHovered] = useState(false);

    useCursor(isHovered)

    const [sectionOpenedArray, setSectionOpened] = useSectionOpened(state => [state.sectionOpenedArray, state.setSectionOpened])

    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])

    const setTopic = useTopic(state => state.setTopic)

    const hoverAudio = useMemo(() => new Audio('./sounds/hover.wav'), []);
    const clickAudio = useMemo(() => new Audio('./sounds/click.wav'), []);

    const handleClick = () => {
        setTopic(props.topic)
        setSectionOpened(props.sectionIndex)
        clickAudio.play()
    }

    const handleHover = () => {
        hoverAudio.play()
        setIsHovered(true)
    }

    const time = useRef({value: 0});
    const goalProgress = useRef(0.25);

    useEffect(() => {
        if (isHovered) {
            goalProgress.current = 1;
        } else {
            goalProgress.current = 0;
        }

    }, [isHovered])

    useFrame(() => {
        const ALPHA = 0.2;
        time.current.value = time.current.value + (goalProgress.current - time.current.value) * ALPHA
    })

    //click hint
    useEffect(() => {

        if (isHovered) return;
        if (currentSection !== props.sectionIndex) return;
        if (sectionOpenedArray[props.sectionIndex] === true) return;

        let firstDelayTimeout = null;
        let timeoutId = null;
        let intervalId = null;

        const startHint = () => {
            goalProgress.current = 0.5

            timeoutId = setTimeout(() => {
                goalProgress.current = 0
            }, 175)
        }

        firstDelayTimeout = setTimeout(() => {
            startHint()

            intervalId = setInterval(() => {
                startHint()
            }, 4000)
        }, 3000)

        return () => {
            clearInterval(intervalId)
            clearTimeout(timeoutId)
            clearTimeout(firstDelayTimeout)
        }
    }, [isHovered, currentSection, sectionOpenedArray])

    return (
        <>
            <mesh {...props}
                  onPointerEnter={handleHover}
                  onPointerLeave={() => setIsHovered(false)}
                  onPointerDown={() => setIsHovered(true)}
                  onPointerUp={() => setIsHovered(false)}
                  onClick={handleClick}
            >
                <planeGeometry args={[.2, .2]}/>
                <shaderMaterial
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={{
                        u_time: time.current,
                        map: {value: texture}
                    }}
                    transparent
                    forceSinglePass
                    // alphaTest={0.0}
                    depthWrite={false}
                    depthTest={false}
                />
            </mesh>
        </>
    )
}

const vertexShader = `

    varying vec2 vUv;

    void main() {
    
      vUv = uv;
    
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;     
    
      gl_Position = projectedPosition;
}
`;
const fragmentShader = `

    varying vec2 vUv;   
    uniform float u_time;
    uniform sampler2D map;
    
    vec2 rotate(vec2 uv, float angle) {
        // Define the pivot point
        vec2 pivot = vec2(0.5, 0.5);
    
        // Translate UV coordinates so that pivot becomes the origin
        uv -= pivot;
    
        // Compute sine and cosine of the angle
        float s = sin(angle);
        float c = cos(angle);
    
        // Create the rotation matrix
        mat2 rotationMatrix = mat2(c, -s, s, c);
    
        // Apply rotation
        uv = rotationMatrix * uv;
    
        // Translate UV coordinates back to their original position
        uv += pivot;
    
        return uv;
    }
    
void main() {

    vec2 uv = vUv;

    uv = rotate(uv, 3.1415 * u_time);

    vec4 texColor = texture2D(map, uv);
    
    vec3 orange = vec3(242.0 / 255.0, 97.0 / 255.0, 63.0 / 255.0);  
    vec3 white = vec3(1.0); 
    
    vec3 color = mix(orange, white, u_time);     
    
    gl_FragColor = vec4(color.rgb * (1.0 - texColor.rgb), texColor.a - 0.1);             
}
    
`;

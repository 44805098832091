import create from 'zustand';

const getDefaultLanguage = () => {

    const option = window.navigator.userLanguage || window.navigator.language

    const split = option.split("-")[0];

    if (split === 'pl' || split === 'en' || split === "uk") {

    }

    console.log(split ? split : 'en')

    return split ? split : 'en'
    // return "uk"
}

const useLanguageStore = create((set, get) => ({
    language: getDefaultLanguage(),
    setLanguage: (language) => set({language}),
}));

export default useLanguageStore;

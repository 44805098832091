import styles from './SlicedText.module.css'

export default function SlicedText({children}) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.top}>{children}</div>
            <div className={styles.bottom} aria-hidden="true">{children}</div>
        </div>
    )
}

import {useFrame, useThree} from "@react-three/fiber";
import {useEffect, useLayoutEffect, useRef} from "react";
import * as THREE from "three";
import {useScrollPercentage} from "../hooks/zustand/useScrollPercentage";
import {useCurrentSection} from "../hooks/zustand/useCurrentSection";

export default function Camera() {

    const cameraSettings= [
        [0.53, 1.5],
        [1.5, 0.5],
        [-0.56, -0.25],
        [0.54, -1.25]
    ]

    const {camera} = useThree();

    const currentSection = useCurrentSection(state => state.currentSection)

    const startPosition = useRef([0, 0])
    const currentPosition = useRef([0, 0]);
    const goalPosition = useRef([0.53, 1.5]);

    useLayoutEffect(() => {
        moveTo(camera, currentPosition, [0.53, 1.5]);
        startPosition.current = [0.53, 1.5];
    }, [])

    const animationStartTime = useRef(0)

    useEffect(() => {

        startPosition.current = goalPosition.current;
        animationStartTime.current = Date.now();
        goalPosition.current = cameraSettings[currentSection]

    }, [currentSection])

    useFrame(() => {
        const animationDuration = 3000;

        let animationProgress = (Date.now() - animationStartTime.current) / animationDuration;
        animationProgress = Math.max(Math.min(animationProgress, 1), 0)
        animationProgress = easeInOutQuint(animationProgress)

        const targetPosition = [
            interpolate(startPosition.current[0], goalPosition.current[0], animationProgress),
            interpolate(startPosition.current[1], goalPosition.current[1], animationProgress),
        ]

        moveTo(camera, currentPosition, targetPosition)

        if (animationProgress === 1) {
            startPosition.current = currentPosition.current;
        }
    })

    return null;
}

function moveTo(camera, currentPositionRef, targetPosition) {

    const moveVector = [targetPosition[0] - currentPositionRef.current[0], targetPosition[1] - currentPositionRef.current[1]];

    currentPositionRef.current = targetPosition;

    moveCameraRelative(camera, moveVector[0], moveVector[1]);
}

function moveCameraRelative(camera, x, y) {
    // Get the camera's direction
    const forward = new THREE.Vector3(0, 1, 0);
    forward.applyQuaternion(camera.quaternion);

    // Get the camera's right vector
    const right = new THREE.Vector3(1, 0, 0);
    right.applyQuaternion(camera.quaternion);

    // Calculate the movement vector
    const movement = forward.clone().multiplyScalar(y).add(right.clone().multiplyScalar(x));

    // Update the camera's position
    camera.position.add(movement);
}

function interpolate(startValue, endValue, factor) {
    // Make sure the factor is within [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the interpolated value
    return startValue * (1 - factor) + endValue * factor;
}

function easeInOutSine(x) {
    return -(Math.cos(Math.PI * x) - 1) / 2;
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

function easeInOutQuint(x) {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}

import Scene from "./scene/Scene";
import styles from './App.module.css'
import ScrollControls from "./controls/ScrollControls";
import {useScrollPercentage} from "./hooks/zustand/useScrollPercentage";
import {useEffect, useMemo, useRef} from "react";
import {useCurrentSection} from "./hooks/zustand/useCurrentSection";
import Overlay from "./overlay/Overlay";
import {useTopic} from "./hooks/zustand/useTopic";
import useFrameAnimation from "./hooks/useFrameAnimation";


export default function App() {

    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])
    const [topic, setTopic] = useTopic(state => [state.topic, state.setTopic])

    const transitionAudio = useMemo(() => new Audio('./sounds/transition2.wav'), []);

    useEffect(() => {
        setTopic(null)

        if (currentSection > 0) {
            setTimeout(() => {
                transitionAudio.play();
            }, 1000)
        }

    }, [currentSection])

    useEffect(() => {
        setTimeout(() => {
            transitionAudio.play();
        }, 1000)
    }, [topic])

    return (
        <div className={styles.mainContainer}>

            <SceneContainer>
                <Scene/>
            </SceneContainer>

            <Overlay/>

        </div>
    );
}

function SceneContainer({children}) {

    const ref = useRef();

    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])
    const lastChangeTime = useRef(0)
    const CHANGE_RESTRICTION = 3000;

    const currentTopic = useTopic(state => state.topic)

    const currentTransform = useRef(0);

    const startProgress = useRef(0)
    const goalProgress = useRef(0);

    const animationStartTime = useRef(0)

    const isFirstRender = useRef(true)

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (currentTopic !== null) {
            startProgress.current = 0;
            goalProgress.current = 20;
        } else {
            startProgress.current = 20;
            goalProgress.current = 0;
        }

        animationStartTime.current = Date.now();

    }, [currentTopic])

    useFrameAnimation(() => {
        const animationDuration = 3000;

        let animationProgress = (Date.now() - animationStartTime.current) / animationDuration;
        animationProgress = Math.max(Math.min(animationProgress, 1), 0)
        animationProgress = easeInOutCubic(animationProgress)

        const progress = interpolate(startProgress.current, goalProgress.current, animationProgress);

        currentTransform.current = progress;
        ref.current.style.transform = `translateX(${-progress}vw)`
    })

    const handleWheel = (event) => {

        if (Math.abs(event.deltaY) > 50 && Date.now() - lastChangeTime.current > CHANGE_RESTRICTION) {
            const direction = (event.deltaY / Math.abs(event.deltaY))
            setCurrentSection(currentSection + direction)
            lastChangeTime.current = Date.now()
        }
    }

    const touchStartRef = useRef(null);

    const handleTouchStart = (event) => {
        touchStartRef.current = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
        if (touchStartRef.current !== null) {

            const touchEnd = event.changedTouches[0].clientY;
            const distance = touchEnd - touchStartRef.current;

            // Adjust this value based on your desired sensitivity
            const sensitivity = 50;

            if (distance > sensitivity) {
                // Simulate scrolling up
                handleWheel({deltaY: -1 * CHANGE_RESTRICTION});
            } else if (distance < -sensitivity) {
                // Simulate scrolling down
                handleWheel({deltaY: 1 * CHANGE_RESTRICTION});
            }

            touchStartRef.current = null;
        }
    };

    return (
        <div className={styles.sceneContainer}
             onWheel={handleWheel}
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}
             ref={ref}
        >
            {children}
        </div>
    )
}

function interpolate(startValue, endValue, factor) {
    // Make sure the factor is within [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the interpolated value
    return startValue * (1 - factor) + endValue * factor;
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useCursor, useTexture} from "@react-three/drei";
import {useCurrentSection} from "../hooks/zustand/useCurrentSection";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {useTopic} from "../hooks/zustand/useTopic";
import cubeRenderTarget from "three/addons/renderers/common/CubeRenderTarget";
import {useSectionOpened} from "../hooks/zustand/useSectionOpened";

export default function ArrowField(props) {

    const texture = useTexture('./images/arrow.png', (texture) => {
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;
    })

    const [isHovered, setIsHovered] = useState(false);

    useCursor(isHovered)

    const [currentSection, setCurrentSection] = useCurrentSection(state => [state.currentSection, state.setCurrentSection])

    const sectionOpenedArray = useSectionOpened(state => state.sectionOpenedArray)

    const hoverAudio = useMemo(() => new Audio('./sounds/hover.wav'), []);
    const clickAudio = useMemo(() => new Audio('./sounds/click.wav'), []);

    const handleClick = () => {
        setCurrentSection(props.sectionIndex + 1)
        clickAudio.play()
    }

    const handleHover = () => {
        hoverAudio.play()
        setIsHovered(true)
    }

    const time = useRef({value: 0});
    const goalProgress = useRef(0.25);

    useEffect(() => {
        if (isHovered) {
            goalProgress.current = 1;
        } else {
            goalProgress.current = 0;
        }

    }, [isHovered])

    useFrame(() => {
        const ALPHA = 0.2;
        time.current.value = time.current.value + (goalProgress.current - time.current.value) * ALPHA
    })

    //click hint
    useEffect(() => {

        if (isHovered) return;
        if (currentSection !== props.sectionIndex) return;
        if (sectionOpenedArray[props.sectionIndex] === false) return;

        let firstDelayTimeout = null;
        let timeoutId = null;
        let intervalId = null;

        const startHint = () => {
            goalProgress.current = 0.5

            timeoutId = setTimeout(() => {
                goalProgress.current = 0
            }, 125)
        }

        firstDelayTimeout = setTimeout(() => {
            startHint()

            intervalId = setInterval(() => {
                startHint()
            }, 10000)
        }, 4000)

        return () => {
            clearInterval(intervalId)
            clearTimeout(timeoutId)
            clearTimeout(firstDelayTimeout)
        }
    }, [isHovered, currentSection, sectionOpenedArray])

    return (
        <>
            <mesh {...props}
                  onPointerEnter={handleHover}
                  onPointerLeave={() => setIsHovered(false)}
                  onPointerDown={() => setIsHovered(true)}
                  onPointerUp={() => setIsHovered(false)}
                  onClick={handleClick}
            >
                <planeGeometry args={[.2, .2]}/>
                <shaderMaterial
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={{
                        u_time: time.current,
                        map: {value: texture}
                    }}
                    transparent
                    forceSinglePass
                    // alphaTest={0.0}
                    depthWrite={false}
                    depthTest={false}
                />
            </mesh>
        </>
    )
}

const vertexShader = `

    varying vec2 vUv;

    void main() {
    
      vUv = uv;
    
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;     
    
      gl_Position = projectedPosition;
}
`;
const fragmentShader = `

    varying vec2 vUv;   
    uniform float u_time;
    uniform sampler2D map;
    
    vec3 drawDiamond(vec2 fragCoord, vec2 center, float width, float height, vec3 insideColor, vec3 outsideColor) {
        vec2 dist = abs(fragCoord - center);
        
        float diag = (width * height) / sqrt(width * width + height * height);
        
        if (dist.x / width + dist.y / height <= 1.0 && dist.x + dist.y <= diag) {
            return insideColor; // Inside color
        } else {
            return outsideColor; // Outside color
        }
    }
    
void main() {

    vec2 uv = vUv;
    uv = vec2(fract(uv.x - u_time), uv.y);

    vec4 texColor = texture2D(map, uv);
    
    vec3 orange = vec3(242.0 / 255.0, 97.0 / 255.0, 63.0 / 255.0);  
    vec3 white = vec3(1.0);
    
    vec3 color;
    
    if (vUv.x - u_time > 0.0) {
            color =  orange;
        } else {
            color =  white;
        }
    
    gl_FragColor = vec4(color.rgb, texColor.a - 0.1);     
}
    
`;

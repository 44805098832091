/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/models/bodzio.glb --transform
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from "three";
import {useFrame, useThree} from "@react-three/fiber";
import useIsMobile from "../hooks/useIsMobile";

export default function Model(props) {
  const { nodes, materials } = useGLTF('./models/bodzio-optimized-transformed.glb')

    const isMobile = useIsMobile()

    useFrame((state) => {
        if (!isMobile) {
            state.events.update()
        }
    })

    const materialRef = useRef();

    const pointerUv = useRef(new THREE.Vector2(0, 0))

    const handlePointerMove = (event) => {
        pointerUv.current.set(event.uv.x, event.uv.y)
    }

  return (
    <group {...props} dispose={null}>
        <mesh geometry={nodes.backup004.geometry}
              position={[0, 0, 0]}
              // onPointerMove={handlePointerMove}
        >
            <shaderMaterial
                ref={materialRef}
                fragmentShader={fragmentShader}
                vertexShader={vertexShader}
                uniforms={{
                    u_mouse: {
                        value: pointerUv.current,
                    },
                }}
                transparent
                forceSinglePass
            />
        </mesh>
    </group>
  )
}

useGLTF.preload('./models/bodzio-optimized-transformed.glb')

const vertexShader = `

    varying vec2 vUv;

    void main() {
    
      vUv = uv;
    
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;     
    
      gl_Position = projectedPosition;
}
`;
const fragmentShader = `

    varying vec2 vUv;

    uniform vec2 u_mouse;
    uniform float u_time;

    float rand(vec2 n) { 
        return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
    }

    float noise(vec2 p){
        vec2 ip = floor(p);
        vec2 u = fract(p);
        u = u*u*(3.0-2.0*u);
        
        float res = mix(
        mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
        mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
        return res*res;
    }

vec4 grid(vec4 color, float size, float width) {
        
    vec2 st = vUv;
    
    float offset = - 0.00015;
    
    st = st + offset;
    
    vec2 gridFactor = mod(st, 1.0 / size);
       
    gridFactor *= size * (1.0 + width);
    
    gridFactor = floor(gridFactor);
    
    float factor = max(gridFactor.x, gridFactor.y);
    
    vec4 black = vec4(0.0);
    
    return mix(black, color, factor);
    }

vec4 overlay(vec4 color1, vec4 color2) {
    return vec4(color1.xyz + color2.xyz , color1.w * color2.w);
}

void main() {
    
    vec2 st = vUv;
    vec2 mouse = u_mouse.xy;
    
    vec4 transparent = vec4(0.0);
    vec4 black = vec4(12.0 / 255.0, 12.0 / 255.0, 12.0 / 255.0, 1.0);
    vec4 orange = vec4(242.0 / 255.0, 97.0 / 255.0, 63.0 / 255.0, 1.0);
    vec4 blue = vec4(0.0, 0.0, 1.0, 1.0);
    
    float centerDistance = distance(vec2(0.5), st);
    float pointDistance = distance(vec2(0.6, 0.4), st);
    
    float distanceInflu = min(centerDistance, pointDistance);
    
    vec4 narrowGrid = grid(orange, pow(2.0, 7.0), 0.03);
    vec4 narrowGridFaded = vec4(narrowGrid.rgb, narrowGrid.a - distanceInflu * 3.5);
    
    vec4 wideGrid = grid(orange, pow(2.0, 5.0), 0.01);
    vec4 wideGridFaded = vec4(wideGrid.rgb, wideGrid.a - centerDistance * 1.5);

    vec4 mixedGrid = vec4(max(narrowGridFaded, wideGridFaded));
    // vec4 mixedGrid = wideGridFaded;
    
    float cursorInflu = smoothstep(0.0, noise(st * 10.0 + 1.0) * 0.5, distance(mouse, st));
    cursorInflu *= 6.0;
    
    vec4 narrowGridCursored = mix(narrowGrid , vec4(0.0), cursorInflu);
    vec4 mixedGridCursored = vec4(max(mixedGrid, narrowGridCursored));
    
    gl_FragColor = mixedGridCursored;  
}
    
`;

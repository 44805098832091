/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/models/bodzio-line.glb --transform
*/

import React, {useEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import * as THREE from "three";
import {useFrame, useThree} from "@react-three/fiber";
import {useCurrentSection} from "../hooks/zustand/useCurrentSection";

export default function Model(props) {

    const {gl} = useThree()

    console.log(gl.info.render.triangles)

    const lineSettings= [
        0.25,
        0.4,
        0.65,
        1
    ]

    const {nodes} = useGLTF('./models/line-optimized-transformed.glb')

    const time = useRef({value: 0})

    const currentSection = useCurrentSection(state => state.currentSection)

    const startProgress = useRef(0)
    const goalProgress = useRef(0.25);

    const animationStartTime = useRef(0)

    const isFirstRender = useRef(true);

    useEffect(() => {
        animationStartTime.current = Date.now()
        startProgress.current = 0;
        goalProgress.current = lineSettings[0]
    }, [])

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        startProgress.current = goalProgress.current;
        animationStartTime.current = Date.now();
        goalProgress.current = lineSettings[currentSection]

    }, [currentSection])

    useFrame(() => {
        const animationDuration = 3000;

        let animationProgress = (Date.now() - animationStartTime.current) / animationDuration;
        animationProgress = Math.max(Math.min(animationProgress, 1), 0)
        animationProgress = easeInOutCubic(animationProgress)

        const progress = interpolate(startProgress.current, goalProgress.current, animationProgress);


        time.current.value = progress;

        if (animationProgress === 1) {
            startProgress.current = goalProgress.current;
        }
    })


    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.line001.geometry}>
                <shaderMaterial
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={{
                      u_time: time.current,
                    }}
                    transparent
                    forceSinglePass
                    depthTest={false}
                />
            </mesh>
        </group>
    )
}

useGLTF.preload('./models/line-optimized-transformed.glb')

const vertexShader = `

    varying vec2 vUv;

    void main() {
    
      vUv = uv;
    
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;     
    
      gl_Position = projectedPosition;
}
`;
const fragmentShader = `

    varying vec2 vUv;   
    uniform float u_time;
    
    float rand(vec2 n) { 
        return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
    }

    float noise(vec2 p){
        vec2 ip = floor(p);
        vec2 u = fract(p);
        u = u*u*(3.0-2.0*u);
        
        float res = mix(
        mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
        mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
        return res*res;
    }
    
void main() {

    float time = u_time;        
         
    float alpha = 0.95 - (time - vUv.y) * 5.0;
    vec4 orange = vec4(242.0 / 255.0, 97.0 / 255.0, 63.0 / 255.0, alpha);  
    vec4 black = vec4(1.0, 1.0, 1.0, 0.0);            
        
    gl_FragColor = orange;     
      
    if (vUv.y > time) {
        discard;
    }    
}
    
`;

function interpolate(startValue, endValue, factor) {
    // Make sure the factor is within [0, 1]
    factor = Math.max(0, Math.min(1, factor));

    // Calculate the interpolated value
    return startValue * (1 - factor) + endValue * factor;
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
